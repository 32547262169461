
import cls from "./CharacterBlock.module.css";
import {classNames} from "../../lib/classNames/classNames";
import Title from "../Title/Title";


const CharacterBlock = ({className, character}) => {
    const { name, img, icon } = character;
    return (
        <div className={classNames(cls.CharacterBlock, {}, [className])}>
            <div className={cls.CharacterBlock__img}>
                <img src={img} alt={name.join(" ")} />
            </div>

            <div className={cls.CharacterBlock__info}>
                <div className={cls.CharacterBlock__name}>
                    <Title
                        className={cls.CharacterBlock__title}
                        title={name}
                    />
                </div>

                <div className={cls.CharacterBlock__icon}>
                    <img src={icon} alt={name.join(" ")}/>
                </div>
            </div>
        </div>
    )
}

export default CharacterBlock;