import Gamer from "../../shared/assets/images/Gamer.png";
import Arrow from "../../shared/assets/images/Arrow.png";
import cls from "./Main.module.css";
import Title from "../../shared/ui/Title/Title";
import ParagraphSection from "../../shared/ui/ParagraphSection/ParagraphSection";
import MarginBottomContainer from "../../shared/ui/MarginBottomContainer/MarginBottomContainer";
import ScrollButton from "../../shared/ui/ScrollButton/ScrollButton";
import LineHeader from "../../shared/ui/LineHeader/LineHeader";
const Main = () => {
    console.log(cls);
    return (
        <div id="main" className={cls.mainSection}>
            <div className="container container--main">
                <div className={cls.mainWrapper}>
                    <div className={cls.mainWrapper__info}>
                        <MarginBottomContainer margin={22}>
                            <LineHeader />
                            <Title title="Digital Times" />
                        </MarginBottomContainer>

                        <MarginBottomContainer margin={123}>
                            <ParagraphSection>
                                <span style={{whiteSpace: "pre-line"}}>
                                    {`Добро пожаловать в Диджитал Таймс!\n
                                    В Диджитал Таймс мы стремимся к инновациям. Если вы - компания, стремящаяся к революционному изменению своего бизнеса, или частный пользователь, ищущий передовые приложения, у нас есть то, что вам нужно.\n
                                    Наша команда экспертов стремится воплотить ваши идеи в реальность. Уделяя особое внимание творчеству и качеству, мы стремимся превзойти ваши ожидания.\n
                                    Присоединяйтесь к нам, чтобы вместе исследовать бесконечные возможности цифрового мира.`}
                                </span>
                            </ParagraphSection>
                        </MarginBottomContainer>
                        <ScrollButton src={Arrow} href="aboutUs" label="О НАС" />

                    </div>
                    <div>
                        <img src={Gamer} alt=""/>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Main;
