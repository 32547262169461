import Title from "../../shared/ui/Title/Title";
import Mission from "../../shared/assets/images/ourMission.png";
import ParagraphSection from "../../shared/ui/ParagraphSection/ParagraphSection";
import cls from "./Mission.module.css";
import MarginBottomContainer from "../../shared/ui/MarginBottomContainer/MarginBottomContainer";
import LineHeader from "../../shared/ui/LineHeader/LineHeader";
const OurMission = () => {
    return (
        <div id="mission" className="mission-section">
            <div className="container container--main">
                <div className={cls.missionWrapper}>
                    <div className="mission-wrapper__header">
                        <MarginBottomContainer margin={22}>
                            <LineHeader />
                            <Title title="Наша миссия" />
                        </MarginBottomContainer>
                    </div>
                    <MarginBottomContainer margin={90}>
                        <div className={cls.missionWrapperInfo}>
                            <ParagraphSection>
                                <span style={{fontSize: 26}}>
                                    Наша миссия в Диджитал Таймс ясна: революция в цифровом мире с помощью передовых мобильных решений. Мы стремимся создавать инновационные решения, которые переосмысливают способы взаимодействия людей с технологиями. От концепции до исполнения мы ставим во главу угла креативность, совершенство и удовлетворенность пользователей, гарантируя, что каждый наш продукт оставит неизгладимое впечатление. Присоединяйтесь к нам, ведь мы формируем будущее мобильных технологий, создавая революционные решения каждый раз.
                                </span>
                            </ParagraphSection>
                        </div>
                    </MarginBottomContainer>

                    <div className={cls.missionWrapperImage}>
                        <img className="img" src={Mission} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurMission;
