import cls from "./Header.module.css";
import Logo from "../../shared/assets/images/Logo.png";
import {Link} from "react-router-dom";
const Header = () => {
    return (
        <header className={cls.header}>
            <div className={`container container--main ${cls.headerContainer}`}>
                <div className={cls.header__logo} onClick={() => window.location.href = "/#main"}>
                    <div className={cls.logoContainer}>
                        <img src={Logo} alt=""/>
                    </div>
                </div>

                <nav className={cls.header__menu}>
                    <li><Link to="/#aboutUs">О компании</Link></li>
                    <li><Link to="/#mission">Наша миссия</Link></li>
                    <li><Link to="/#career">Вакансии</Link></li>
                    <li><Link to="/#contact">Контакты</Link></li>
                    <li><Link to="/privacy-policy">Политика Конфиденциальности</Link></li>
                    <li><Link to="/terms">Условия и положения</Link></li>
                    <li><Link to="/copyright">Copyright</Link></li>
                </nav>

            </div>
        </header>
    );
};

export default Header;
