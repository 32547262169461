import Inbox from "../../shared/assets/images/inbox.png";
import cls from "./ContactUs.module.css";
import MarginBottomContainer from "../../shared/ui/MarginBottomContainer/MarginBottomContainer";
import Title from "../../shared/ui/Title/Title";
import LineHeader from "../../shared/ui/LineHeader/LineHeader";

const ContactUs = () => {
    return (
        <div id="contact" className="contact-section">
            <div className="container container--main">
                <div className={cls.contactWrapper}>
                    <div className="contact-wrapper__info">
                        <MarginBottomContainer margin={43}>
                            <LineHeader />
                            <Title title="Контакты" />
                        </MarginBottomContainer>

                        <div className="contacts">
                            <p className={cls.pWeak}>
                                <span className={cls.pStrong}>ceo@digitaltimes.by</span> - по всем вопросам
                            </p>
                        </div>

                    </div>
                    <div className={cls.contactWrapper__image}>
                        <img src={Inbox} alt=""/>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ContactUs;
