import DevilSkibidi from "./../../assets/CharacterImgs/DevilSkibidi.png";
import HorrorSirenHead from "./../../assets/CharacterImgs/HorrorSIRENHEAD.png";
import HorrorToiletRobo from "./../../assets/CharacterImgs/HorrorToiletRobo.png";
import MonkeyLu from "./../../assets/CharacterImgs/MonkeyLu.png";
import PoppyHuggy from "./../../assets/CharacterImgs/PoppyHuggy.png";
import PoppyKissy from "./../../assets/CharacterImgs/PoppyKissy.png";
import PoppyMummy from "./../../assets/CharacterImgs/PoppyMummy.png";
import SpringBonnieMonster from "./../../assets/CharacterImgs/SpringBonnieMonster.png";
import TitanTVMan from "./../../assets/CharacterImgs/TitanTV-Man.png";
import TVMan from "./../../assets/CharacterImgs/TV-Man.png";

import DevilSkibidiIcon from "./../../assets/CharacterIcons/DevilSkibidi.png";
import HorrorSirenHeadIcon from "./../../assets/CharacterIcons/HorrorSIRENHEAD.png";
import HorrorToiletRoboIcon from "./../../assets/CharacterIcons/HorrorToiletRobo.png";
import MonkeyLuIcon from "./../../assets/CharacterIcons/MonkeyLu.png";
import PoppyHuggyIcon from "./../../assets/CharacterIcons/PoppyHuggy.png";
import PoppyKissyIcon from "./../../assets/CharacterIcons/PoppyKissy.png";
import PoppyMummyIcon from "./../../assets/CharacterIcons/PoppyMummy.png";
import SpringBonnieMonsterIcon from "./../../assets/CharacterIcons/SpringBonnieMonster.png";
import TitanTVManIcon from "./../../assets/CharacterIcons/TitanTV-Man.png";
import TVManIcon from "./../../assets/CharacterIcons/TV-Man.png";


export const characters = [
    {
        name: ["Devil\nSkibidi"],
        img: DevilSkibidi,
        icon: DevilSkibidiIcon
    },
    {
        name: ["Horror\nSIREN HEAD"],
        img: HorrorSirenHead,
        icon: HorrorSirenHeadIcon
    },    {
        name: ["Horror\nToilet Robo"],
        img: HorrorToiletRobo,
        icon: HorrorToiletRoboIcon
    },    {
        name: ["Monkey\nLu"],
        img: MonkeyLu,
        icon: MonkeyLuIcon
    },    {
        name: ["Poppy\nHuggy"],
        img: PoppyHuggy,
        icon: PoppyHuggyIcon
    },    {
        name: ["Poppy\nKissy"],
        img: PoppyKissy,
        icon: PoppyKissyIcon
    },
    {
        name: ["Poppy\nMummy"],
        img: PoppyMummy,
        icon: PoppyMummyIcon
    },    {
        name: ["Spring Bonnie\nMonster"],
        img: SpringBonnieMonster,
        icon: SpringBonnieMonsterIcon
    },
    {
        name: ["Titan\nTV - Man"],
        img: TitanTVMan,
        icon: TitanTVManIcon
    },    {
        name: ["TV - Man"],
        img: TVMan,
        icon: TVManIcon
    },
]