import {classNames} from "../../shared/lib/classNames/classNames";
import cls from "./CopyrightPage.module.css";
import Title from "../../shared/ui/Title/Title";
import {characters} from "../../shared/assets/characters";
import CharacterBlock from "../../shared/ui/CharacterBlock/CharacterBlock";
import Paragraph from "../../shared/ui/Paragraph/Paragraph";


const CopyrightPage = () => {

    return (
        <section
            className={classNames("page-section", {}, [cls.copyrightPage, "container container--main"])}>
            <div className={classNames("container container__main container__page")}>
                <Title
                    className={cls.copyrightPage__title}
                    title={"COPYRIGHTED MATERIALS"}
                />
                <Paragraph paragraphContent={[
                    {
                        type: "text",
                        content: `Owned by Adverta Limited\n\n`
                    },
                    {
                        type: "text",
                        content: `All the materials mentioned were independently created as work for hire by Adverta Limited, having its registered address at The L.Plaza, Unit 1603, 16th Floor, 367 - 375 Queen's Road, Central Hong Kong Sheung Wan 999077.

All the materials mentioned are subject to copyright protection worldwide and are currently submitted for official copyright registration within the US Copyright Office with a Service Request No 1-14035126121.

Any and all rights, including copyright in the mentioned materials, are owned exclusively by Adverta Limited.

Supplemental Rights Agreement with Digital Times

Pursuant to a supplemental agreement between Adverta Limited and Digital Times, having its registered address at Minsk, Karl Liebknecht St., Building 66, Office 212, Digital Times also holds rights to the aforementioned characters. This includes, but is not limited to, the right to use, reproduce, and distribute the materials, subject to the terms and conditions set forth in the supplemental agreement. However, the copyright and exclusive ownership of the materials remain with Adverta Limited unless otherwise explicitly stated.`
                    },
                ]}
                />
                <div className={cls.characters}>
                    {characters.map((character, key) => {
                        return (
                            <>
                                <CharacterBlock className={cls.characters__item} key={key} character={character}/>
                                <div className={cls.characters__divider}/>
                            </>
                        )
                    })}
                </div>
                </div>
        </section>
)
}

export default CopyrightPage;
