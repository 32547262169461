import cls from "./Title.module.css";
import {classNames} from "../../lib/classNames/classNames";
const Title = ({title, className}) => {
    return (
        <h1 className={classNames(cls.Title, {}, [className])}>
            {title}
        </h1>
    )
}

export default Title;
