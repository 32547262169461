import AboutImg from "../../shared/assets/images/aboutUs.png";
import ArrowUp from "../../shared/assets/images/ArrowUp.png";
import { useState } from "react";
import Title from "../../shared/ui/Title/Title";
import ParagraphSection from "../../shared/ui/ParagraphSection/ParagraphSection";
import cls from "./About.module.css";
import MarginBottomContainer from "../../shared/ui/MarginBottomContainer/MarginBottomContainer";
import ScrollButton from "../../shared/ui/ScrollButton/ScrollButton";
import LineHeader from "../../shared/ui/LineHeader/LineHeader";
const AboutUs = () => {
    const [visible, setVisible] = useState(false)
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 800){
            setVisible(true)
        }
        else if (scrolled <= 300){
            setVisible(false)
        }
    };


    window.addEventListener('scroll', toggleVisible);

    return (
        <div id="aboutUs" className="about-section">
            <div style={{display: visible ? "flex" : "none"}} className={cls.up} onClick={() => window.location.href = "#main"}>
                <ScrollButton src={ArrowUp} />
            </div>
            <div className="container container--main">
                <div className={cls.aboutWrapper}>
                    <MarginBottomContainer margin={22}>
                        <LineHeader />
                        <Title title="О компании" />
                    </MarginBottomContainer>

                    <div className={cls.aboutWrapper__info}>
                        <div style={{flexBasis:"50%", marginRight: "33px"}}>
                            <ParagraphSection>
                                <span style={{whiteSpace: "pre-line"}}>
                                    {`Добро пожаловать в Диджитал Таймс, где инновации определяют все направления нашей деятельности.\n
                                    В Диджитал Таймс мы больше, чем просто компания, - мы команда увлеченных разработчиков, стремящихся изменить представление о цифровых технологиях. От задумки до исполнения мы ставим во главу угла совершенство, креативность и удовлетворенность пользователей в каждом нашем проекте.\n
                                    Наша разносторонняя команда объединяет богатый опыт и знания для создания передовых решений, которые оставляют неизгладимое впечатление. Мы верим в силу технологий, способных вдохновлять и расширять возможности, и стремимся создавать цифровой опыт, который меняет жизнь к лучшему.\n
                                    Присоединяйтесь к нам, ведь мы ведем за собой будущее технологий и преобразуем цифровой бизнес к лучшему.`}
                                </span>
                            </ParagraphSection>
                        </div>
                        <div className={cls.aboutWrapper__image}>
                            <img className="img" src={AboutImg} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
