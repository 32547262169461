import TikTok from "../../shared/assets/images/TikTok.png";
import Youtube from "../../shared/assets/images/Youtube.png";
import Facebook from "../../shared/assets/images/Facebook.png";
import Instagram from "../../shared/assets/images/Instagram.png";
import Close from "../../shared/assets/images/close.png";
import TikTokHover from "../../shared/assets/images/TikTokHover.png";
import YouTubeHover from "../../shared/assets/images/YouTubeHover.png";
import FacebookHover from "../../shared/assets/images/FacebookHover.png";
import InstagramHover from "../../shared/assets/images/InstagramHover.png";
import cls from "./Footer.module.css";


import { Modal } from "../../components/Modal/Modal";
import { useCallback, useState } from "react";
import Title from "../../shared/ui/Title/Title";
import MarginBottomContainer from "../../shared/ui/MarginBottomContainer/MarginBottomContainer";

const Footer = () => {
    const [isModal, setModal] = useState(false);
    const onToggleModal = useCallback(() => {
        setModal((prevState) => !prevState);
    }, []);
    return (
        <footer className={cls.footer}>
            <div className={cls.footerSocials}>
                <div className="container container--main footer__container">
                    <div className={cls.footerSocial}>
                        <div className={cls.footerCopyright}>
                            {`
                            ООО "ДИДЖИТАЛ ТАЙМС"
                            Адрес: БЕЛАРУСЬ, МИНСКАЯ ОБЛ., МИНСКИЙ Р-Н,
                            Г. МИНСК, УЛ. КАРЛА ЛИБКНЕХТА, ДОМ 66, ОФ. 212, ПОМ. 71, 220036
                            УНП: 193689609`}
                        </div>
                        <div className={cls.footerSocialLinks}>
                            <a href="" className={cls.footerLink} onClick={(e) => e.preventDefault()}>
                                <img className={cls.opacity} src={TikTokHover} alt=""/>
                                <img src={TikTok} alt=""/>
                            </a>
                            <a href="" className={cls.footerLink} onClick={(e) => e.preventDefault()}>
                                <img className={cls.opacity} src={YouTubeHover} alt=""/>
                                <img src={Youtube} alt=""/>
                            </a>
                            <a href="" className={cls.footerLink} onClick={(e) => e.preventDefault()}>
                                <img className={cls.opacity} src={FacebookHover} alt=""/>
                                <img src={Facebook} alt=""/>
                            </a>
                            <a href="" className={cls.footerLink} style={{marginRight:0}} onClick={(e) => e.preventDefault()}>
                                <img className={cls.opacity} src={InstagramHover} alt=""/>
                                <img src={Instagram} alt=""/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className={cls.footerAllrights}>
                <div className="container container--main footer__container">
                    <div className={cls.footerAllrightsWrapper}>
                        <div className={cls.footerSocialCookie} onClick={() => onToggleModal()}>
                            Политика использования файлов cookie
                        </div>
                        <div className={cls.footerAllrightsRights}>
                            ©2024 DIGITAL TIMES. Все права защищены.
                        </div>
                    </div>
                </div>
            </div>

            <Modal isOpen={isModal} onClose={onToggleModal}>
                <img className={cls.closeImg} src={Close} alt="" onClick={onToggleModal}/>
                <MarginBottomContainer margin={22}>
                    <Title title="Политика использования файлов cookie" />
                </MarginBottomContainer>

                <p className={cls.cookieP}>
                    Сторонние файлы cookie для обеспечения производительности и функциональности.
                </p>
                <br/>
                <p className={cls.cookieP}>
                    Эти файлы cookie помогают нам настроить и улучшить ваш опыт работы в ДИДЖИТАЛ ТАЙМС. Например, они помогают нам запомнить ваши предпочтения и избавляют вас от необходимости повторно вводить ранее предоставленную информацию (например, при заполнении анкеты).  Удаление этих типов файлов cookie может привести к ограничению функциональности нашего сервиса.
                </p>
                <br/>
                <p className={cls.cookieP}>
                    Большинство файлов cookie действуют только в течение одного дня. Некоторые файлы cookie активны в течение более длительного времени - от 3 до 12 месяцев.
                </p>
            </Modal>
        </footer>
    );
};

export default Footer;
