import Header from "./../pages/Header/Header";
import Main from "./../pages/Main/Main";
import AboutUs from "./../pages/AboutUs/AboutUs";
import Career from "./../pages/Career/Career";
import ContactUs from "./../pages/ContactUs/ContactUs";
import Footer from "./../pages/Footer/Footer";
import OurMission from "./../pages/OurMission/OurMission";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import ScrollToAnchor from "../components/ScrollToAnchor/ScrollToAnchor";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import Terms from "../pages/Terms/Terms";
import CopyrightPage from "../pages/CopyrightPage/CopyrightPage";

function App() {
    return (
        <Router>
            <ScrollToAnchor />
            <div className="App">
                {/* Общий компонент Header */}
                <Header />

                <Routes>
                    {/* Главная страница со всеми секциями */}
                    <Route
                        path="/"
                        element={
                            <>
                                <Main />
                                <AboutUs />
                                <OurMission />
                                <Career />
                                <ContactUs />
                                <Footer />
                            </>
                        }
                    />

                    {/* Отдельные страницы */}
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/copyright" element={<CopyrightPage />} />
                </Routes>
            </div>
        </Router>
  );
}

export default App;
